import { render, staticRenderFns } from "./memberConsume.vue?vue&type=template&id=b4457228&scoped=true&"
import script from "./memberConsume.vue?vue&type=script&lang=js&"
export * from "./memberConsume.vue?vue&type=script&lang=js&"
import style0 from "./memberConsume.vue?vue&type=style&index=0&id=b4457228&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4457228",
  null
  
)

export default component.exports